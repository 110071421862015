import { LazyLoad } from "./modules/lazy-load.min.js";
import { TemplateJS } from "./modules/template.min.js";

let appLenis = {};
let isPageLoad = true;
let swup = null;

// Initialize page
function init() {
  // Initialize Gsap + ScrollTrigger
  gsap.registerPlugin(ScrollTrigger);

  // Init scroll monitor
  transitionCallback();

  // Initialize Swup
  swup = new Swup({
    containers: ["#swup", "#navbar", ".footer"],
    cache: false,
  });

  // Set transition callback
  swup.hooks.on("content:replace", transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on("page:view", function () {
    if ($(".iubenda-embed").length > 0) {
      location.reload();
    }
  });
}

function initLenis() {
  appLenis = {
    initialized: false,
    _lenis: null,

    init() {
      this._lenis = new Lenis({
        lerp: 0.085,
      });

      this.initEvents();
      this.initialized = true;
    },

    initEvents() {
      this._lenis.on("scroll", ScrollTrigger.update);

      gsap.ticker.add((time) => {
        this._lenis.raf(time * 1000);
      });
    },

    destroy() {
      this._lenis.stop();
      this._lenis.destroy();
    },
  };

  appLenis.init();
}

function destroyAnimations() {
  const Alltrigger = ScrollTrigger.getAll();
  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true);
  }
}

// Callback called on swup transition
function transitionCallback() {
  // Destroy prvious animations
  destroyAnimations();

  // Stop lenis to prevent scrolling during transitions
  if (appLenis.initialized) {
    appLenis.destroy();
  }

  // Initialize Lenis
  initLenis();

  // Iubenda cleanup
  iubendaCheck();

  // Initialize news page functions
  initNewsPage();

  // Initialize contact page functions
  initContactPage();

  // Reinitialize lazy load
  new LazyLoad().init();

  // Initialize JS template
  new TemplateJS().init();
}

function initNewsPage() {
  // Add callback for ordering news items
  $(".order_by").on("click", () => {
    let list = $(".grid");
    let listItems = list.children("div");
    list.append(listItems.get().reverse());

    $(".order_by").toggleClass("reverse");
  });
}

function initContactPage() {
  if ($("#contact-form").length > 0) {
    // Show recaptcha badge
    $(".grecaptcha-badge").addClass("visible");

    // Submit form callback
    $("#contact-form #submit_form").on("click", (e) => {
      e.preventDefault();
      submitContactForm();
    });
  } else {
    // Hide reCaptcha badge
    $(".grecaptcha-badge").removeClass("visible");
  }
}

function submitContactForm() {
  // Get form
  let form = $("#contact-form");

  // Remove all errors
  form.find(".error").removeClass("error");

  // Get form data
  let data = form.serialize();

  let error = false;

  // --- Validate form ---
  // Name
  if (form.find("#contactform-name").val() == "") {
    form.find("#contactform-name").addClass("error");
    error = true;
  } else {
    form.find("#contactform-name").removeClass("error");
  }

  // Surname
  if (form.find("#contactform-surname").val() == "") {
    form.find("#contactform-surname").addClass("error");
    error = true;
  } else {
    form.find("#contactform-surname").removeClass("error");
  }

  // Email
  if (form.find("#contactform-email").val() == "") {
    form.find("#contactform-email").addClass("error");
    error = true;
  } else {
    form.find("#contactform-email").removeClass("error");
  }

  // Body
  if (form.find("#contactform-body").val() == "") {
    form.find("#contactform-body").addClass("error");
    error = true;
  } else {
    form.find("#contactform-body").removeClass("error");
  }

  // If error has occurred, stop form submission
  if (error) {
    return false;
  }

  // Submit form
  submitRecaptcha((token) => {
    // Add recaptcha token to form data
    data += "&g-recaptcha-response=" + token;

    $.ajax({
      url: "/access/contact",
      type: "POST",
      data: data,
      success: function (data) {
        if (data.status == "form-error") {
          // Show error message
          for (let key in data.message) {
            if (data.message.hasOwnProperty(key)) {
              form.find(".result-text").html(data.message[key]);
              form.find(".result-text").fadeIn(500);
            }
          }
          return;
        } else if (data.status == "error") {
          // Show generic error message
          form.find(".result-text").html("Unknown error! Retry later.");
          form.find(".result-text").fadeIn(500);
          return;
        }

        // Show success message
        form.find(".result-text").html(data.message);
        form.find(".result-text").fadeIn(500);

        // Clear form
        form.find("#contactform-name").val("");
        form.find("#contactform-surname").val("");
        form.find("#contactform-email").val("");
        form.find("#contactform-body").val("");
        form.find("#contactform-phone").val("");
      },
      error: function (data) {
        // Show error message
        form.find(".result-text").html("Unknown error! Retry later.");
        form.find(".result-text").fadeIn(500);
      },
    });
  });
}

function iubendaCheck() {
  // Remove iubenda cookie message
  setTimeout(function () {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "none";
            },
          );

          // Send events to GTM
          switch (purposes_iub[i]) {
            case "2":
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: "iubenda-interazioni-semplici",
              });
              break;
            case "3":
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: "iubenda-esperienza-migliorata",
              });
              break;
            case "4":
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: "iubenda-misurazione" });
              break;
            case "5":
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: "iubenda-pubblicita" });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "flex";
            },
          );
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $(".content-before-consent").each(function (_, el) {
        el.style.display = "block";
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

function removePreloader() {
  isPageLoad = false;

  // Remove preloader
  const preloaderBg = document.querySelector("#preloader");
  const preloaderLogo = document.querySelector("#preloader img");
  const tl = gsap.timeline();

  tl.to(preloaderLogo, {
    opacity: 0,
    y: "-8rem",
    duration: 1.2,
    delay: 0.5,
  }).to(preloaderBg, {
    opacity: 0,
    duration: 1,
    delay: -0.25,
    onComplete: () => {
      preloader.remove();
    },
  });
}

// Initialize page
$(window).on("load", function () {
  init();
  removePreloader();
});
